import { Layout } from 'components/global'
import SubHeader from 'components/shared/subHeader'
import { Link, navigate } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

const ErrorStyles = styled.div`
  width: 100%;
  background: var(--offWhite);
  color: var(--darkGrey);
  text-align: center;
  padding: 5rem 0;
  button {
    margin-top: 2rem;
  }
`
const NotFoundPage = () => (
  <Layout>
    <SubHeader />
    <ErrorStyles>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/">
        <button className="btn" type="button">
          <span>Home</span>
          <div className="border" />
          <div className="border" />
        </button>
      </Link>
    </ErrorStyles>
  </Layout>
)

export default NotFoundPage
